import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Infodetail from "./Infodetail";
import "./info.css";

function Info() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          shouldAutoplay: true,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          shouldAutoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="info">
        <div className="steps">
          <h1>Let's Start Ecobrick-ing!</h1>
          <hr />
        </div>
        <Slider {...settings}>
          {Infodetail.map((item, k) => (
            <div className="details" key={k}>
              <img src={item.infoimg} alt="img" className="info_img" />
              <div className="info_detail">{item.detail}</div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
export default Info;
