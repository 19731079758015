import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaInstagramSquare, FaFacebookSquare } from "react-icons/fa";
import Moreinfo from "./Moreinfo";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="containerr">
          <div className="footer-content">
            <div className="footer-column">
              <h2>Contact Us</h2>
              <p>savevasundharaa@gmail.com</p>
            </div>
            <div className="footer-column">
              <h2>Connect with us on social media.</h2>
              <div className="social">
                <ul>
                  <li>
                    <Link to="https://instagram.com/save_vasundhara?igshid=MzRlODBiNWFlZA==">
                      <FaInstagramSquare className="instagramm" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/profile.php?id=100091677003456&mibextid=LQQJ4d">
                      <FaFacebookSquare className="facebookk" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="footerbottom">
        {currentYear}
        <p>&copy; {currentYear}| Tearm's & Policies | All rights reserved.</p>
      </div>
      <Moreinfo />
    </>
  );
}
export default Footer;
