import React, { useState } from "react";
import "./navbar.css";
import { FaInstagramSquare, FaFacebookSquare } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";

function Navbar() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <>
      <div className="nav">
        <div className="brand_img">
          <img src="Logo.jpg" alt="logo" className="logo" />
          <h2>
            <a href="/">
              <span>S</span>ave
              <span>V</span>asundhara
            </a>
          </h2>
        </div>

        <div className={showMediaIcons ? "menu  mobile_menu_link" : "menu"}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/Team">Our Team</Link>
            </li>
            <li>
              <Link to="/About">About</Link>
            </li>
            <li>
              <Link to="/Contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="social">
          <ul className="social_mediadesktop">
            <li>
              <Link to="https://instagram.com/save_vasundhara?igshid=MzRlODBiNWFlZA==">
                <FaInstagramSquare className="instagram" />
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/profile.php?id=100091677003456&mibextid=LQQJ4d">
                <FaFacebookSquare className="facebook" />
              </Link>
            </li>
          </ul>
          <div className="hamburger_menu">
            <Link to="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default Navbar;
