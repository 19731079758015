import React from "react";
import { Link } from "react-router-dom";
import "./section.css";

function Section() {
  return (
    <>
      <div className="container grid grid_col">
        <div className="container_inside">
          <h1>
            Project Of<strong> Save Vasundhra</strong>
          </h1>
          <h2 className="sub_inside"></h2>
          <div className="mainbtn">
            <Link to="/Team" className="btn">
              More Details
            </Link>
          </div>
        </div>
        <div className="sub_container">
          <img src="earth2.png" alt="sidelogo" className="earthlogo" />
        </div>
      </div>
    </>
  );
}

export default Section;
