import React from "react";
import Cardvideo from "./Cardvideo";
import Sideabout from "./Sideabout";
import Info from "./Info";

function About() {
  return (
    <>
      <div>
        <Sideabout />
        <Info />
        <Cardvideo />
      </div>
    </>
  );
}
export default About;
