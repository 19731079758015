const Data = [
  {
    id: 1,
    linkvideo: "Lilgirls.mp4",
  },
  {
    id: 2,
    linkvideo: "Lilgirl.mp4",
  },
  {
    id: 3,
    linkvideo: "prathmesh.mp4",
  },
  {
    id: 4,
    linkvideo: "Lilboy.mp4",
  },
];
export default Data;
