import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Imgdata from "./Imgdata";
import "./sideabout.css";

function Sideabout() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="card1">
        <Slider {...settings}>
          {Imgdata.map((item, j) => (
            <div className="div_img" key={j}>
              <img src={item.barimage} alt="img" className="upper_images" />
              <h3 className="h3info">{item.info}</h3>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
export default Sideabout;
