import React, { useState } from "react";
import { questions } from "./api";
import MyAccordion from "./MyAccordion";
import "./accordion.css";

const Accordion = () => {
  const [data, setData] = useState(questions);
  return (
    <>
      <section className="main_div">
        <h1>Do you have questions?</h1>
        {data.map((curElem) => {
          return <MyAccordion key={curElem.id} {...curElem} />;
        })}
      </section>
    </>
  );
};
export default Accordion;
