const Infodetail = [
  {
    id: 1,
    infoimg: "plastic_bottle.png",
    detail: `step 1: Find A Plastic Bottle Find any used / waste Plastic bottle of 1 liter / 750ml Wash & Dry it.`,
  },
  {
    id: 2,
    infoimg: `plastic_dust_bin.png`,
    detail: `step 2: Find A Plastic Bottle Find any used / waste
    Plastic bottle of 1 liter / 750ml Wash & Dry it.`,
  },
  {
    id: 3,
    infoimg: `ecobottle.png`,
    detail: `step 3: Find A Plastic Bottle Find any used / waste
    Plastic bottle of 1 liter / 750ml Wash & Dry it.`,
  },
];
export default Infodetail;
