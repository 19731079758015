import React from "react";
import "./team.css";
import { Link } from "react-router-dom";

function Team() {
  return (
    <>
      <div className="team">
        <div className="image1">
          <img src="women.jpg" alt="women" className="womenimg" />
        </div>
        <div className="para">
          <h2>
            We are Udaan group driving Save Vasundhara Campaign 🌎 As a team we
            are escalating the idea of plastic waste management. In this we are
            taking awareness programs in the schools of Buldhana thus making our
            new generation to get the sanskar of managing waste . In this way
            they will help themselves as well as their elders and younger
            brother and sister and people around them. Ultimately serving the
            mankind and the surrounding around us. So we request all to please
            be conscious and awake while you have a waste plastic bag our
            plastic bit in your hands.Save Vasundhara 🌍🇮🇳 Jai hind Jai Bharat.
          </h2>
        </div>
        <div className="teambtn">
          <Link to="/Contact" className="teambtn1">
            Contact
          </Link>
        </div>
      </div>
    </>
  );
}
export default Team;
