import "./moreinfo.css";
function Moreinfo() {
  return (
    <>
      <div className="footerbottom1_">
        <h3>For website info:</h3>
        <h4> aditipatil8598@gmail.com</h4>
      </div>
    </>
  );
}
export default Moreinfo;
