const Imgdata = [
  {
    id: 1,
    barimage: "teampic1.JPG",
    info: "Save Vasundhara Team",
  },
  {
    id: 2,
    barimage: "teampic2.JPG",
    info: "Plastic awareness rally by students",
  },
  {
    id: 3,
    barimage: "aaji.jpg",
    info: "Not only students and schools, housewives also participating in eco-bricks program",
  },
  {
    id: 4,
    barimage: "schoolboys.jpg",
    info: "Students",
  },
];
export default Imgdata;
