import React from "react";
import "./home.css";
import Section from "./Section";
import About from "./Cardvideo";
import Contact from "./Contact";
import Accordion from "./accordion/Accordion";

function Home() {
  return (
    <>
      <Section />
      <Accordion />
      <About />

      <Contact />
    </>
  );
}
export default Home;
