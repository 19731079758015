export const questions = [
  {
    id: 1,
    question: "Eco-Brick means?",
    answer: "Eco:- Environment friendly, Brick:- Veet",
  },
  {
    id: 2,
    question: "How to make Eco-brick?",
    answer:
      "Take any dry 1 lit/750 ml plastic bottle. Collect the plastic garbage from your Home like ( plastic bages of Chocolate, Busckit, Kurkure, Chips, Rava, Maida, Pohe, Used Buds, Medicine Tablets Cover, used Staple Pins, Used Plastic Pens, Plastic Spoons, Used Plastic Dry Glass, etc). You can use the rod or stick to push the plastic in the bottle and make your own eco-brick.",
  },
  {
    id: 3,
    question: "Uses of Eco-brick?",
    answer:
      "1.It can be used in building compound walls. 2.It can be used to make Chair and Benches. 3.It can be used in garden decorations. By making correct use of plastic we can start making our environment clean.",
  },
  {
    id: 4,
    question: "Why do we need Eco-bricks?",
    answer:
      "As we all know use of plastic now a days is excessive as it has very hazadous effects on environment like blockage of seaver lines, Soil pollution, increasing garbage dumping area which causes air pollution as plastic is non-degradable so people burn it to get rid of plastic. There are mountains of plastic in seas which are affecting waterlife and also causes situations like tsunami. Even the street animal life is in danger they eat plastic which leads to death. Considering these facts we have cameup with an initiative which can be applied easily in our day to day life.",
  },
  {
    id: 5,
    question: "what happens when you burn plastic?",
    answer:
      "Burning plastic releases toxic gases and particles into the air. These are harmful for humans and animals health, and causes environmental damage",
  },
  {
    id: 6,
    question: "Morel",
    answer:
      "Our small afforts can save millions of life of in future generations, by providing them clean Water, Air and Environment.",
  },
];
