import React, { useState } from "react";
import "./contact.css";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      {/*} <div className="contact">
         <h2 className="contact_heading">Feel Free to Contact Us</h2>

        <div className="form_container">
          <div className="contact_form">
            <form onSubmit={sendto} className="sub_form">
              <input
                type="text"
                name="form_name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Name here..."
                autoComplete="off"
                required
              />
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email here..."
                autoComplete="off"
                required
              />
              <textarea
                placeholder="Type here your message..."
                name="text"
                onChange={(e) => setText(e.target.value)}
                cols="40"
                rows="7"
                autoComplete="off"
                required
              ></textarea>
                </form>
              </div>
            </div>
          </div> */}

      <div className="container grid grid_col contact">
        <div className="container_inside">
          <h1>Support Our Project</h1>
          <h2 className="sub_inside"></h2>

          <div className="mainbtn">
            <Link to="https://mail.google.com" className="btn">
              Send Us Mail
            </Link>
          </div>
        </div>
        <div className="sub_container ">
          <h2>Our Location:</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29891.704407798137!2d76.16594886065849!3d20.528226533894355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bda1df8cd6d9cb1%3A0x5f36a09324f0bb4d!2sBuldana%2C%20Maharashtra%20443001!5e0!3m2!1sen!2sin!4v1703060555557!5m2!1sen!2sin"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}
export default Contact;
