import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Data from "./Data.jsx";
import "./cardvideo.css";

function Cardvideo() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="card">
        <Slider {...settings}>
          {Data.map((item, i) => (
            <div className="cards" key={i}>
              <video
                src={item.linkvideo}
                alt="video"
                className="vid"
                controls
              ></video>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
export default Cardvideo;
